import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as WeatherActions from '../reducers/weather';
import * as WeatherThunkActions from "../thunks/weather";
import * as Utils from '../utils';
import * as Constants from '../utils/constants';
import { WeatherContext } from './weatherContext';

export const WeatherProvider = ({ children }) => {
  // Redux state management
  const dispatch          = useDispatch();
  const weatherData       = useSelector(state => state.weather);

  // Component states
  const [error, setError] = useState(undefined);
  const [info, setInfo]   = useState(undefined);
  const [city, setCity]   = useState('');
  const [lat, setLat]     = useState(undefined);
  const [lon, setLon]     = useState(undefined);

  const hideError = () => {
    setError(undefined);
    dispatch(WeatherActions.setError(false));
  }

  const getGeoPositon = async () => {

    const urlCity = getLocationFromURL();
    const isRadar = Utils.getURLLocation() === '/radar';
    
    if(!isRadar) {
      Utils.setLocalStorageItem(Constants.LOCAL_STORAGE_KEY_GPS_POSITION, null);
    }

    if (Utils.getURLParam(Constants.URL_PARAM_LAT) && Utils.getURLParam(Constants.URL_PARAM_LON)) {
      setLat(Utils.getURLParam(Constants.URL_PARAM_LAT));
      setLon(Utils.getURLParam(Constants.URL_PARAM_LON));
      return;
    }

    const isLatLonSet = (Utils.getLocalStorageItem(Constants.LOCAL_STORAGE_KEY_GPS_POSITION) !== null);
    
    // if(!urlCity && !isRadar) {
      if(!urlCity && !isLatLonSet) {
      try {
        const { latitude, longitude } = await Utils.getBrowserGeoPosition();
        setLat(latitude);
        setLon(longitude);
        Utils.savePosition(latitude, longitude);
      } catch(e) {
        setError(e);
      }
    } else if(!isRadar) {
      setCity(urlCity);
    } else {
      if(Utils.getLocalStorageItem(Constants.URL_PARAM_LAT) && Utils.getLocalStorageItem(Constants.URL_PARAM_LON)) {
        setLat(Utils.getLocalStorageItem(Constants.URL_PARAM_LAT));
        setLon(Utils.getLocalStorageItem(Constants.URL_PARAM_LON));
      }
    }
  }

  const getLocationFromURL = () => {
    if (Utils.getURLLocation() !== undefined && Utils.getURLLocation() !== '/' && Utils.getURLLocation() !== '/radar') {
      Utils.setLocalStorageItem(Constants.LOCAL_STORAGE_KEY_GPS_POSITION, null);
      Utils.setLocalStorageItem(Constants.URL_PARAM_LAT, null);
      Utils.setLocalStorageItem(Constants.URL_PARAM_LON, null);
      const urlLocation = Utils.getURLLocation();
      return urlLocation.substring(1);
    }
  } 
  
  useEffect(() => {
    getGeoPositon();
  }, []);
  
  
  useEffect(() => {
    if (lat && lon) {
      dispatch(WeatherThunkActions.getWeatherByLatLon({lat, lon}));
    }
  }, [dispatch, lat, lon]);

  useEffect(() => {
    if(city) {
      dispatch(WeatherThunkActions.getWeatherByCity({city}));
    }
  }, [dispatch, city]);


  const contextValue = {
    dispatch,
    error,
    hideError,
    city,
    setCity,
    lat,
    setLat,
    lon,
    setLon,
    info,
    setInfo,
    weatherData,
  };

  return (
    <WeatherContext.Provider value={contextValue}>
      {children}
    </WeatherContext.Provider>
  );
}

WeatherProvider.propTypes = {
  children: PropTypes.node.isRequired
}