import React from 'react';
import { getLocalStorageItem } from '../utils';
import { LOCAL_STORAGE_KEY_GPS_POSITION } from '../utils/constants.js';
import Loader from './Loader.tsx';
import SearchForm from './SearchForm.jsx';

import { useWeather } from '../providers/weatherContext';


const Radar =  () => {
  let positionLocalStorage = getLocalStorageItem(LOCAL_STORAGE_KEY_GPS_POSITION);
  if(positionLocalStorage === null) {
    const { weatherData, error} = useWeather();
    if (weatherData.loading) {
      return (
        <>
        <Loader numOfCols={8} />
        <Loader numOfCols={10} />
          <Loader numOfCols={10} />
          <Loader numOfCols={5} />
          <Loader numOfCols={7} />
          <Loader numOfCols={9} />
          <Loader numOfCols={8} />
        </>
      );
    }

  }

  positionLocalStorage = getLocalStorageItem(LOCAL_STORAGE_KEY_GPS_POSITION);
  let lat;
  let lon;
  if (positionLocalStorage !== null) {
    lat = positionLocalStorage.lat;
    lon = positionLocalStorage.lon;
  }
  
  const renderRadarIfNoError = () => {
    if (positionLocalStorage) {
      let embedLink =
      'https://embed.windy.com/embed2.html?lat=' +
      lat +
      '&lon=' +
      lon +
      '&detailLat=' +
      lat +
      '&detailLon=' +
      lon +
      '&width=650&height=450&zoom=8&level=surface&overlay=radar&product=radar&menu=&message=true&marker=&calendar=now&pressure=&type=map&location=coordinates&detail=&metricWind=km%2Fh&metricTemp=%C2%B0C&radarRange=-1';

      console.log(embedLink)

      return (
        <>
          <iframe
            allow='fullscreen'
            style={{
              border: 0,
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              width: '100%',
              height: '500px',
            }}
            src={embedLink}
          ></iframe>
        </>
      )
    }
  }
  
  return (
    <div className='pt-3'>
       { renderRadarIfNoError() }
     </div>
  );
};

export default Radar;
