import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

function Links() {
  // const { weatherData } = useWeather();
  // let lat;
  // let lon;
  // let latLon = '';
  // const positionLocalStorage = getLocalStorageItem(LOCAL_STORAGE_KEY_GPS_POSITION);
  //   if (positionLocalStorage !== null) {
  //     lat = positionLocalStorage.lat;
  //     lon = positionLocalStorage.lon;
  //   }

  // if (!lat && !lon) {
  //   if(weatherData.loading) {
  //     return (
  //       <>
  //         <Loader numOfCols={8} />
  //         <Loader numOfCols={10} />
  //       </>
  //     );
  //   } else {
  //     if(!weatherData.error) {
  //      const latLonData = weatherData.data[0];
  //      lat = latLonData.weatherLat;
  //      lon = latLonData.weatherLon;
  //     }
  //   }
  // }
  // latLon = `?l=${lat},${lon}`;
  
  const generateLinks = () => {
    let linkId = 0;
    return [
      { id: linkId++, image: require('../assets/img/ctv-news.jpg'), linkURL: 'https://calgary.ctvnews.ca/' },
      { id: linkId++, image: require('../assets/img/ec-weather-1.jpg'), linkURL: 'http://weather.gc.ca/city/pages/ab-52_metric_e.html' },
      { id: linkId++, image: require('../assets/img/windy.png'), linkURL: '/radar' },
      { id: linkId++, image: require('../assets/img/660-news.jpg'), linkURL: 'http://660news.com' },
      { id: linkId++, image: require('../assets/img/cbc-calgary-1.jpg'), linkURL: 'http://cbc.ca/news/canada/calgary' },
      { id: linkId++, image: require('../assets/img/global-calgary-1.jpg'), linkURL: 'http://globaltvcalgary.com' },
      { id: linkId++, image: require('../assets/img/google-1.jpg'), linkURL: 'http://news.google.com' },
      { id: linkId++, image: require('../assets/img/slashdot-icon.png'), linkURL: 'http://slashdot.org' },
    ];
  };
    

  return (
    <Row className='justify-content-center'>
      {generateLinks().map((linkdetails, index) => (
        <Col xs='3' md='1' className='mb-3' key={linkdetails.id}><a href={linkdetails.linkURL}><img className='img-thumbnail img-fluid' src={linkdetails.image} /></a></Col>
      ))}
    </Row>
  );
}
export default Links;
