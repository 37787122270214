import React from 'react';


import DailyWeatherConditions from './DailyWeatherConditions';
import HourlyWeatherConditionsTiles from './HourlyWeatherConditionsTiles';
import MediaCardCurrentConditions from './MediaCardCurrentConditions.jsx';
import { WeatherAlert } from './WeatherAlert.jsx';

import { useWeather } from '../providers/weatherContext.js';
import Notification from './Notification.jsx';
import SearchForm from './SearchForm.jsx';
import Radar from './Radar.jsx';

// Home component
const Home = ({children}) => {
  const {
    weatherData,
    error,
    hideError,
    info,
    setInfo
  } = useWeather();
  const renderErrorIfAny = () => {
    if ((weatherData && weatherData.error) || error) {
      let withError = error;
      if (weatherData && weatherData.error) {
        withError = weatherData.error;
      }
      return (
        <div className='alert alert-danger' role='alert'>
          <h4 className='alert-heading'>{withError}</h4>
          Please try searching for a location below. 
          <SearchForm />
        </div>
      );
      
    }
  }
  const renderNotificationIfAny = () => {
    if (info) {
      return <Notification message={info} hideNotification={() => setInfo(undefined)} type='info' />
    }
  }
  const renderWeatherAlertIfAny = () => {
    if(weatherData.data.alerts) {
      return <><WeatherAlert /></>
    }
  } 
  const renderWeatherIfNoError = () => {
    if (weatherData && !weatherData.error) {
      if(children !== undefined) {
        return (
        <>
          <a href="/" className='btn btn-dark'>Home</a>
          { children }
        </>
        )
      } else {
        return (
            <>
                <MediaCardCurrentConditions />
                <SearchForm />
                <HourlyWeatherConditionsTiles />
                <DailyWeatherConditions />
            </>
          )
        }

      }
  }
  return(
    <div className='pt-3'>
      { renderWeatherAlertIfAny() }
      { renderWeatherIfNoError() }
      { renderErrorIfAny() }
      { renderNotificationIfAny() }
    </div>
  );
};
export default Home;
