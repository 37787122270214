import React from "react";
import { createRoot } from 'react-dom/client';
//https://erikflowers.github.io/weather-icons/
import { Provider } from 'react-redux';
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import "./assets/weather-icons/css/weather-icons.min.css";
import { WeatherProvider } from './providers/weatherProvider';
import * as serviceWorker from "./serviceWorker";
import { store } from './store/store';


import App from "./App";
import Home from "./components/Home";
import Footer from './components/Footer';
import Links from "./components/Links";
import Radar from './components/Radar';

const weatherInfoContainer = document.getElementById('root');
const weatherInfoRoot = createRoot(weatherInfoContainer);

weatherInfoRoot.render(
  <Provider store={store}>
    <WeatherProvider>
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/radar" exact element={<Home><Radar /></Home>} />
        <Route path="*" element={<Home />} />
      </Routes>
        <Links />      
      <Footer />
    </Router>
    </WeatherProvider>
  </Provider>
);

serviceWorker.unregister();